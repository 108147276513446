import React from 'react';
import type { DropdownOptionProps } from 'wix-ui-tpa/cssVars';
import { LocationSmall } from '@wix/wix-ui-icons-common';
import { useStyles } from '@wix/tpa-settings/react';
import { stylesParams } from 'root/components/Menus/stylesParams';
import { useLocationsBi } from './Locations.bi';
import type { LocationsList } from 'root/types/locations';
import { useExperiments, useTranslation } from '@wix/yoshi-flow-editor';

export const useLocationsLogic = (
  locations: LocationsList,
  currentLocation: string,
  setCurrentLocation: (location: string) => void
) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const locationsDropdownStyle = styles.get(stylesParams.locationsDropdownStyle);
  const { reportLocationNavigationClick } = useLocationsBi();
  const { experiments } = useExperiments();
  const isDropdownBottomExperimentEnabled = experiments.enabled('specs.restaurants.menusOoiDropdownBottom');
  const dropdownOptions: DropdownOptionProps[] = Object.entries(locations).map(
    ([id, value]): DropdownOptionProps => ({
      id,
      value,
      isSelectable: true,
      icon: <LocationSmall />,
    })
  );

  const onClickDropdownOption = (locationId: string, locationName: string) => {
    setCurrentLocation(locationId);
    reportLocationNavigationClick({
      locationId,
      locationName,
      numberOfLocations: dropdownOptions.length,
      previousLocationId: currentLocation,
      previousLocationName: locations[currentLocation],
    });
  };

  return {
    dropdownOptions,
    locationsDropdownStyle,
    onClickDropdownOption,
    dropdowmPlacement: isDropdownBottomExperimentEnabled ? 'bottom' : undefined,
    dropdownFlip: isDropdownBottomExperimentEnabled ? false : undefined,
    t,
  };
};
