import React from 'react';
import type { DropdownProps } from 'wix-ui-tpa/cssVars';
import { Dropdown, DropdownTheme } from 'wix-ui-tpa/cssVars';
import { dataHooks } from 'root/dataHooks';
import { useLocationsLogic } from './Locations.logic';
import type { LocationsList } from 'root/types/locations';
import { classes } from './Locations.st.css';
import { NavigationDropdownStyle } from 'root/consts';

export const Locations = ({
  locations,
  currentLocation,
  setCurrentLocation,
  setActiveTab,
}: {
  locations: LocationsList;
  currentLocation: string;
  setCurrentLocation: (location: string) => void;
  setActiveTab: (tab: number) => void;
}) => {
  const { dropdownOptions, locationsDropdownStyle, onClickDropdownOption, dropdowmPlacement, dropdownFlip, t } =
    useLocationsLogic(locations, currentLocation, setCurrentLocation);
  return (
    <div className={classes.locationsContainer} data-hook={dataHooks.locations.container}>
      {dropdownOptions && (
        <Dropdown
          aria-label={t('component.menu.location.aria-label')}
          className={classes.dropdown}
          data-hook={dataHooks.locations.dropdown}
          flip={dropdownFlip}
          options={dropdownOptions}
          placement={dropdowmPlacement as DropdownProps['placement']}
          selectedId={currentLocation}
          theme={locationsDropdownStyle === NavigationDropdownStyle.Box ? DropdownTheme.Box : DropdownTheme.Line}
          onChange={(option) => {
            onClickDropdownOption(option.id ?? '', option.value ?? '');
            setActiveTab(0);
          }}
        />
      )}
    </div>
  );
};
